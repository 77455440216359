import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveAboveCta } from "@dataResolvers";
import { Home as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query HomeQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "homepage", siteId: $siteId) {
        title
        ... on Craft_homepage_homepage_Entry {
          # Hero
          heroTitle
          entrySubtitle
          heroCta {
            text
            url
          }
          heroImage: metaFeaturedImage(withTransforms: ["heroTallExtraLarge"]) {
            width(transform: "heroTallExtraLarge")
            height(transform: "heroTallExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Secondary CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          carousel {
            ...CarouselEntryFragment
          }
          # Meta
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    heroImage,
    entrySubtitle,
    aboveFooterCtaImageBlocks,
    carousel,
    heroCta,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle,
      subtitle: entrySubtitle,
      button: heroCta,
      image: resolveImage(heroImage),
    },
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    carousel: carousel?.map(slide => {
      if (slide?.entry?.length) {
        return {
          ...slide?.entry[0],
          metaFeaturedImage: resolveImage(slide?.entry[0]?.metaFeaturedImage),
        };
      }
      return {
        ...slide,
        metaFeaturedImage: resolveImage(slide?.metaFeaturedImage),
      };
    }),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
